import {Fragment, useState, useEffect} from "react"
import { Link } from "react-router-dom"
import "hamburgers/dist/hamburgers.min.css"
import Config from "../../config.json"
import "./Header.css"

import Instagram from "./instagram.png"
import Behance from "./behance.png"
import Logo from "./logo.png"

const Header = () => {

    const [projects, setProjects] = useState(null)
    const [isOpen, setIsOpen] = useState(false)

    useEffect(() => {
        setProjects(Config.projects)
    }, [])

    useEffect(() => {
        if (isOpen) {
            document.body.classList.add("hidden-scroll")
        } else {
            document.body.classList.remove("hidden-scroll")
        }
    }, [ isOpen ])

    const handleIsOpen = () => {
        setIsOpen(prevState => !prevState)
    }

    const onClickLink = () => {
        setIsOpen(false)
        window.scrollTo(0, 0)
    }

    return projects && (
        <Fragment>
            <header className="header">
                <button onClick={ handleIsOpen } className={ `hamburger hamburger--collapse${ isOpen ? " is-active" : "" }` } type="button">
                    <span className="hamburger-box">
                        <span className="hamburger-inner" />
                    </span>
                </button>
            </header>
            <div className={ `menu${ isOpen ? " menu--is-active" : "" }` }>
                <div className="menu__logo">
                    <img src={ Logo } alt="Белый квадрат" />
                </div>
                <div className="menu__links">
                    <div className="menu__links__projects">
                        <h3>Проекты</h3>
                        {
                            projects.map(p => (
                                <Link key={ p.link } onClick={ onClickLink } to={ `/project/${ p.link }` } className="menu__links__project">{ p.name }</Link>
                            ))
                        }
                    </div>
                    <div className="menu__links__pages">
                        <Link to="/#services" onClick={ onClickLink } className="menu__links__page">Услуги</Link>
                        <Link to="/#steps" onClick={ onClickLink } className="menu__links__page">Процесс</Link>
                        <Link to="/#prices" onClick={ onClickLink } className="menu__links__page">Тарифы</Link>
                        <Link to="/#contacts" onClick={ onClickLink } className="menu__links__page">Контакты</Link>
                    </div>
                </div>
                <div className="menu__social">
                    <a href="https://instagram.com/natashusiy?utm_medium=site" target="_blank" rel="nofollow noreferrer noopener">
                        <img src={ Instagram } alt="Белый квадрат в Instagram" />
                    </a>
                    <a href="https://www.behance.net/1488d89c" target="_blank" rel="nofollow noreferrer noopener">
                        <img src={ Behance } alt="Белый квадрат на Behance" />
                    </a>
                </div>
            </div>
        </Fragment>
    )
}

export default Header