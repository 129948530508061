import { YMaps, Map, Placemark } from "react-yandex-maps"
import "./Contacts.css"

const Contacts = () => (
    <div className="contacts">
        <div className="contacts__content">
            <div className="contacts__content__title">
                <h2>Контакты</h2>
            </div>
            <div className="contacts__content__phones">
                <div className="contacts__content__group">
                    <span>телефон</span>
                    <p>+7 (913) 172-65-35</p>
                </div>
                <div className="contacts__content__group">
                    <span>адрес</span>
                    <p>г. Красноярск,<br/>ул. Октябрьская 10, пом. 196</p>
                </div>
            </div>
        </div>
        <div className="contacts__map">
            <YMaps>
                <Map defaultState={{ center: [56.037106, 92.922443], zoom: 16 }}>
                    <Placemark defaultGeometry={[56.037106, 92.922443]} />
                </Map>
            </YMaps>
        </div>
    </div>
)

export default Contacts