import { useEffect, useState, useLayoutEffect, useRef } from "react"
import { Helmet } from "react-helmet"
import { Link } from "react-router-dom"
import { Swiper, SwiperSlide } from "swiper/react"
import SwiperCore, { Scrollbar } from "swiper"
import { scroller } from "react-scroll"
import { useNavigate } from "react-router-dom"
import { randomProject } from "../../Components/Utils"
import Config from "../../config.json"
import "./Home.css"
import {Feedback, Contacts, Header} from "../../Components"
import '@brainhubeu/react-carousel/lib/style.css'
import "swiper/swiper.min.css"
import "swiper/swiper-bundle.min.css"

import Decor from "./decor.png"
import Control from "./control.png"
import Design from "./design.png"

import Logo from "../../Components/Header/logo.png"

SwiperCore.use([Scrollbar])

const Home = () => {

    const navigate = useNavigate()

    const modal = useRef(null)

    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState(null)
    const [size, setSize] = useState([0, 0])
    const [stepsPerPage, setStepsPerPage] = useState(1)

    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight])
        }
        window.addEventListener("resize", updateSize)
        updateSize()
    }, [])

    useEffect(() => {
        if (size[0] < 992) {
            setStepsPerPage(1)
        } else if (size[0] >= 992 && size[0] < 1200) {
            setStepsPerPage(2)
        } else {
            setStepsPerPage(3)
        }
    }, [ size ])

    useEffect(() => {
        const p = randomProject()
        setProject(p)
        setProjects(Config.projects)
    }, [])

    useEffect(() => {
        const attr = window.location.hash
        if(attr) {
            setTimeout(() => {
                scroller.scrollTo(attr.substring(1, attr.length), {
                    duration: 1000,
                    delay: 0,
                    smooth: 'easeInOutQuart',
                    offset: -60
                })
            }, 250)
        }
    }, [ window.location.hash ])

    const onProjectClick = (e, link) => {
        e.preventDefault()
        navigate(`project/${ link }`)
    }

    const revealContent = (e, id) => {
        e.preventDefault()

        const block = document.querySelector(`[data-price="${ id }"]`)

        if (block.getAttribute("data-state") === "hidden") {
            e.target.innerHTML = "Скрыть"
            block.setAttribute("data-state", "active")
            block.classList.add("price__hidden-description--active")
        } else {
            e.target.innerHTML = "Подробнее"
            block.setAttribute("data-state", "hidden")
            block.classList.remove("price__hidden-description--active")
        }
    }

    const onClose = () => {
        modal.current.classList.remove("modal--isOpen")
    }

    const onClickOpen = () => {
        modal.current.classList.add("modal--isOpen")
    }

    return project && (
        <main className="home">
            <Helmet>
                <title>Белый квадрат</title>
            </Helmet>
            <Header />
            <section className="home__background" style={{ backgroundImage: `url(${ project.main_image })` }}>
                <Link to="/" className="home__logo">
                    <img src={ Logo } alt="Белый квадрат" />
                </Link>
                <div className="project__container--absolute">
                    <h2 className="project__subtitle">{ project.street }</h2>
                </div>
            </section>

            <section className="project__content">
                <div className="project__container project__between">
                    <h1 className="home__title">Проекты</h1>
                </div>
                <div className="project__container">
                    <div className="home__projects">
                        {
                            projects && projects.map(p => (
                                <div
                                    onClick={ e => onProjectClick(e, p.link) }
                                    style={{ backgroundImage: `url(${ p.main_image })` }}
                                    key={ p.link }
                                    className="home__project">
                                    <p>{ p.street }</p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section id="services" className="project__content">
                <div className="project__container project__container--services project__between">
                    <h1 className="home__title">Услуги</h1>
                </div>
                <div className="project__container project__container--steps">
                    <div className="home__services">
                        <div className="home__services__description">
                            <p>Дизайн интерьера состоит из сотен правил и миллиона мелочей. Учесть каждую — наша работа.</p>
                        </div>
                        <div className="home__services__background" />
                        <div className="home__services__stripe">
                            {
                                services.map((s, i) => (
                                    <figure key={ `s_${ i }` } className="service">
                                        <img src={ s.image } alt={ s.name } />
                                        <figcaption>{ s.name }</figcaption>
                                        <p>{ s.text }</p>
                                    </figure>
                                ))
                            }
                        </div>
                    </div>
                </div>
            </section>

            <section id="steps" className="project__content">
                <div className="project__container project__between">
                    <h1 className="home__title">Процесс</h1>
                </div>
                <div className="project__container project__container--steps">
                    <Swiper scrollbar={{
                        "hide": true
                    }} slidesPerView={ stepsPerPage } spaceBetween={16}>
                        {
                            steps.map((s, i) => (
                                <SwiperSlide key={ `step_${ i }` } className="step">
                                    <span>{ i + 1 }</span>
                                    <h4>{ s.title }</h4>
                                    <p>{ s.text }</p>
                                </SwiperSlide>
                            ))
                        }
                    </Swiper>
                </div>
            </section>

            <section id="prices" className="project__content">
                <div className="project__container project__between">
                    <h1 className="home__title">Тарифы</h1>
                </div>
                <div className="project__container">
                    <div className="home__prices">
                        {
                            prices.map((p, i) => (
                                <div key={ `price_${ i }` } className="price">
                                    <div className="price__content">
                                        <h3>{ p.name }</h3>
                                        <p dangerouslySetInnerHTML={{ __html: p.description.short }} />
                                        <div data-price={ i } data-state="hidden" className="price__hidden-description">
                                            <p>Что входит:</p>
                                            <ul className="price__content__list">
                                                {
                                                    p.description.list.map((row, k) => (
                                                        <li key={ `list_${ k }` }>{ row }</li>
                                                    ))
                                                }
                                            </ul>
                                        </div>
                                        <button className="price__more" onClick={ e => revealContent(e, i) }>Подробнее</button>
                                    </div>
                                    <div className="price__order">
                                        <ul className="price__order__list">
                                            {
                                                p.prices.map((row, j) => (
                                                    <li key={ `li_${ i }_price_${ j }` }>{ row.price }<span dangerouslySetInnerHTML={{ __html: row.postfix }} /></li>
                                                ))
                                            }
                                        </ul>
                                        <button className="price__order__button" onClick={ onClickOpen }>Заказать</button>
                                    </div>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="project__content">
                <div className="project__container--full">
                    <Feedback image={ project.form_image } />
                </div>
            </section>

            <section className="project__content">
                <div className="project__container--full">
                    <Contacts />
                </div>
            </section>

            <div ref={ modal } className="modal">
                <div className="modal__inner">
                    <Feedback isPopup />
                    <span className="close" onClick={ onClose }>&times;</span>
                </div>
            </div>

        </main>
    )
}

const prices = [
    {
        name: "Планировочное решение",
        description: {
            short: "<p>Услуга перепланировки предполагает разработку 3-х вариантов расположения мебели и оборудования в рамках существующей площади объекта.</p><p>Разработка вариантов ведется с учетом соблюдения всех СНИПов и норм для дальнейшего согласования утвержденного планировочного решения в необходимых инстанциях.</p>",
            list: [
                "Выезд на объект;",
                "Замер помещения;",
                "Обмерный чертеж с указанием всех размеров и привязкой инженерных коммуникаций;",
                "Разработка трех вариантов планировочного решения."
            ]
        },
        prices: [
            {
                price: 30000,
                postfix: "₽ до 100м<sup>2</sup>"
            },
            {
                price: 45000,
                postfix: "₽ от 100м<sup>2</sup>"
            }
        ]
    },
    {
        name: "Дизайн-проект",
        description: {
            short: "<p>Дизайн проект интерьера представляет собой комплексное решение по организации пространства, состоящее из разработки концепции будущего интерьера, подбора всех отделочных материалов и мебели, а также полного набора рабочих чертежей и 3D визуализации.</p>",
            list: [
                "Обмер помещения;",
                "Составление технического задания (подробное технического задания на проектирование, которое учитывает все пожелания заказчика);",
                "Разработка 3-х вариантов планировочных решений;",
                "Концепция будущего интерьера (коллажи, эскизы, стилевые аналоги);",
                "Работа со смежниками, подготовка коммерческих предложений от поставщиков с учетом обозначенного;",
                "Вами бюджета. Индивидуальный расчет стоимости наполнения помещения;",
                "Подбор отделочных материалов, мебели и светильников;",
                "Совместные поездки по салонам и магазинам;",
                "Полный комплект рабочих чертежей;",
                "Спецификация оборудования и материалов;",
                "Фотореалистичные 3D-визуализации;",
                "Смета на чистовые материалы и оборудование по всем выбранным позициям с указанием стоимости и контактов поставщиков."
            ]
        },
        prices: [
            {
                price: 2000,
                postfix: "₽/м<sup>2</sup>"
            }
        ]
    },
    {
        name: "Авторский надзор",
        description: {
            short: "Авторский надзор представляет собой контроль автора проекта за его реализацией. Дизайнер решает все возникающие в процессе ремонта вопросы по проекту, а также ведет контроль своевременной поставки материалов на объект.",
            list: [
                "Контроль дизайнера за реализацией разработанного им проекта;",
                "Внесение необходимых корректировок и изменений в чертежи, которые могут понадобиться для реализации проекта;",
                "Выезд дизайнера на объект 6 раза в месяц, взаимодействие с прорабом, консультации по телефону;",
                "Согласование с заказчиком счетов на отделочные материалы, оборудование и мебель;",
                "Согласование графика поставки отделочных материалов на объект в соответствии с графиком строительных работ."
            ]
        },
        prices: [
            {
                price: 15000,
                postfix: "₽ в месяц"
            }
        ]
    }
]

const services = [
    {
        name: "Дизайн",
        image: Design,
        text: "Дизайн проект интерьера представляет собой комплексное решение по организации пространства и разработки концепции будущего интерьера."
    },
    {
        name: "Контроль",
        image: Control,
        text: "Дизайнер решает все возникающие в процессе ремонта вопросы по проекту, а также ведет контроль своевременной поставки материалов на объект."
    },
    {
        name: "Декорирование",
        image: Decor,
        text: "Декорирование – это завершающий штрих в разработке дизайна вашего интерьера, который предполагает подбор аксессуаров, текстиля, картин и других декоративных элементов."
    }
]

const steps = [
    {
        "title": "Обращения в студию",
        "text": "Свяжитесь с нами любым удобным способом. Получите развернутую информацию о том, как строиться работа над дизайн проекте, из чего складывается стоимости работ."
    },
    {
        "title": "Заключение договора",
        "text": "Вся информация об этапах, сроках и стоимости оказания услуг, составе дизайн-проекта, а также остальных условиях отражается в договоре. Для учета всех Ваших идей и потребностей, а также технических нюансов составляется подробное техническое задание."
    },
    {
        "title": "Замер помещения",
        "text": "Для подготовки чертежа существующей планировки объекта, дизайнер выезжает и делает полный замер всех помещений с фотофиксацией всех инженерных и коммуникационных узлов."
    },
    {
        "title": "Планировочные решения",
        "text": "На основе технического задания дизайнером предлагает варианты планировочного решения всего проектируемого пространства. Из предлагаемых вариантов Вы выбираете все понравившиеся идеи, вносите свои замечания и дополнения, в результате получается идеальное планировочное решения, учитывающее все Ваши пожелания и потребности."
    },
    {
        "title": "Разработка концепции",
        "text": "Этап разработки стилевого решения вашего будущего интерьера. В виде коллажей и эскизов презентуем ключевые стилеобразующие детали интерьера: цветовую гамму, форму мебели и светильников, а также основные декоративные приемы."
    },
    {
        "title": "Подбор материалов",
        "text": "На основе концепции подбираем реальную мебель и материалов, исходя из заложенного вами бюджета. Вас ждут совместные поездки по салонам и магазинам с дизайнером. Подготовка коммерческих предложений для индивидуального расчета стоимости наполнения каждого помещения."
    },
    {
        "title": "3D визуализация",
        "text": "Для того, чтобы Вы полностью могли представить свой будущий интерьер в деталях. На основе всех выбранных в ходе проектирования материалов, мебели и светильников."
    },
    {
        "title": "Чертежи и смета",
        "text": "Техническая документация включает все выбранные спецификации выбранного оборудования и материалов, развертки помещений, а также ведомости отделки. На основе готового дизайн-проекта считаем смету на строительные работы, а также выбранные в ходе проектирования чистовые материалы, мебель, свет."
    },
    {
        "title": "Авторский надзор",
        "text": "Процесс строительных работ полностью контролируется дизайнером проекта. В ходе авторского надзора осуществляются выезды на объект, проверяется соответствие выполняемых строительных работ разработанному дизайн-проекту, вносятся необходимые правки, а также подбираются материалы, требующие подбора по месту. Ремонтные работы выполняются под ключ на основании разработанного проекта в точности как на визуализации."
    },
    {
        "title": "Готовый объект",
        "text": "После окончания строительных работ по готовому дизайн-проекту вы становитесь счастливым обладателем полностью реализованного под ключ интерьера, не отличающегося от изображения на 3D визуализациях."
    }
]

export default Home