import { useNavigate } from "react-router-dom"
import "./SuggestedProjects.css"

const SuggestedProjects = ({ projects }) => {

    const navigate = useNavigate()

    const onProjectClick = (e, link) => {
        e.preventDefault()
        window.scrollTo(0, 0)
        navigate(`/project/${ link }`)
    }

    return (
        <div className="suggested-projects">
            {
                projects.map(p => (
                    <div
                        onClick={ e => onProjectClick(e, p.link) }
                        key={ p.link }
                        style={{ backgroundImage: `url(${ p.main_image })` }}
                        className="suggested-projects__project">
                        <div className="suggested-projects__project__name">
                            <h2>{ p.street }</h2>
                        </div>
                        <div className="suggested-projects__project__footer">
                            <span>{ p.name }</span>
                            <span>{ p.square }м<sup>2</sup></span>
                        </div>
                    </div>
                ))
            }
        </div>
    )
}

export default SuggestedProjects