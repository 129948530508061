import { BrowserRouter, Route, Routes } from "react-router-dom"
import { Home, Project } from "./Pages"
import "./App.css"

const App = () => {
    return (
        <BrowserRouter>
            <Routes>
                <Route index path="/" element={ <Home /> } />
                <Route path="project/:link" element={ <Project /> } />

                {/*<Route path="*" element={<NoMatch />} />*/}
            </Routes>
        </BrowserRouter>
    )
}

export default App
