import { useState, useRef } from "react"
import NumberFormat from "react-number-format"
import "./Feedback.css"

const Feedback = ({ image, isPopup }) => {

    const [name, setName] = useState("")
    const [phone, setPhone] = useState("")
    const [square, setSquare] = useState("")

    const submitButton = useRef(null)
    const annotation = useRef(null)

    const onSubmit = (e) => {
        e.preventDefault()
        fetch("https://emalion.clockwork.one/send?app=white_square", {
            method: "POST",
            headers: {
                "Content-Type": "application/json"
            },
            body: JSON.stringify({ form: "callback", name, phone, square })
        }).then((res) => {
            if(res.ok)
                return res.json()
            throw Error(`Error with status: ${ res.statusText }`)
        }).then(({ http_code }) => {
            if(http_code === 200) {
                annotation.current.classList.add("feedback__annotation--success")
            } else {
                submitButton.current.disabled = false
                annotation.current.classList.add("feedback__annotation--error")
                annotation.current.innerText = "При отправке заявки произошла ошибка! Пожалуйста, попробуйте позже."
            }
        }).catch((err) => {
            console.error(err)
            submitButton.current.disabled = false
            annotation.current.classList.add("feedback__annotation--error")
            annotation.current.innerText = "При отправке заявки произошла ошибка! Пожалуйста, попробуйте позже."
        })

    }

    return (
        <div className="feedback">
            {
                !isPopup ? <div className="feedback__image" style={{ backgroundImage: `url(${ image })` }} /> : null
            }
            <div className="feedback__content">
                <h3 className="feedback__title">Расчет дизайна</h3>
                <form className="feedback__form" onSubmit={ onSubmit }>
                    <div className="form__group form__name">
                        <input type="text" id="name" name="name" value={ name }  onChange={ ({ target: { value } }) => setName(value) } required />
                        <label htmlFor="form__name" className="form__input">Имя<sup>*</sup></label>
                        <i className="bar" />
                    </div>
                    <div className="form__group form__square">
                        <input type="text" id="square" name="square" value={ square }  onChange={ ({ target: { value } }) => setSquare(value) } required />
                        <label htmlFor="form__name" className="form__input">Площадь, м<sup>3</sup></label>
                        <i className="bar" />
                    </div>
                    <div className="form__group form__phone">
                        <NumberFormat
                            format="+7 (###) ###-####"
                            mask="_"
                            name="phone"
                            value={ phone }
                            onChange={ ({ target: { value } }) => setPhone(value) }
                            allowEmptyFormatting={true}
                            required
                        />
                        <label htmlFor="phone" className="form__input">Телефон<sup>*</sup></label>
                        <i className="bar" />
                    </div>
                    <div className="form__button">
                        <button type="submit" className="feedback__button" ref={ submitButton }>Отправить</button>
                    </div>
                    <p ref={ annotation } className="feedback__annotation">Ваша заявка успешно отправлена!</p>
                </form>
            </div>
        </div>
    )
}

export default Feedback