import Config from "../../config.json"

const findProjectByLink = (link) => {
    return Config.projects.find(p => p.link === link)
}

const otherProjects = (link) => {
    let projects = Config.projects.filter(p => p.link !== link)
    projects = shuffleProjects(projects)
    return projects
}

const randomProject = () => {
    let projects = Config.projects
    projects = shuffleProjects(projects)
    return projects[0]
}

const shuffleProjects = (projects) => {
    for (let i = projects.length - 1; i > 0; i--) {
        let j = Math.floor(Math.random() * (i + 1))
        let temp = projects[i]
        projects[i] = projects[j]
        projects[j] = temp
    }

    return projects
}

export {
    findProjectByLink,
    otherProjects,
    randomProject,
    shuffleProjects
}