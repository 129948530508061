import { useEffect, useState }  from "react"
import { Helmet } from "react-helmet"
import {Link, useParams} from "react-router-dom"
import { findProjectByLink, otherProjects } from "../../Components/Utils"
import {Feedback, Header, SuggestedProjects} from "../../Components"
import "./Project.css"
import Logo from "../../Components/Header/logo.png"

const Project = () => {

    const { link } = useParams()
    const [project, setProject] = useState(null)
    const [projects, setProjects] = useState([])

    useEffect(() => {
        const p = findProjectByLink(link)
        const o = otherProjects(link)
        setProject(p)
        setProjects(o)
    }, [ link ])

    return project && (
        <main className="project">
            <Helmet>
                <title>{ project.name } | Белый квадрат</title>
            </Helmet>
            <Header />
            <section className="project__background" style={{ backgroundImage: `url(${ project.main_image })` }}>
                <Link to="/" className="home__logo">
                    <img src={ Logo } alt="Белый квадрат" />
                </Link>
                <div className="project__container--absolute">
                    <h2 className="project__subtitle">{ project.street }</h2>
                </div>
            </section>
            <section className="project__content">
                <div className="project__container project__between project__intro">
                    <div className="project__place">
                        <span>{ project.city }</span>
                        <h1>{ project.name }</h1>
                    </div>

                    <div className="project__square">
                        <p>{ project.square }м<sup>2</sup></p>
                    </div>
                </div>

                {
                    project.description !== "" ? (
                        <div className="project__container project__center">
                            <p className="project__25rem project__text-center">{ project.description }</p>
                        </div>
                    ) : null
                }

                <div className="project__container project__between p__compare">
                    <figure className="project__compare">
                        <img src={ project.compare.before } alt="До" />
                        <figcaption>До</figcaption>
                    </figure>

                    <figure className="project__compare">
                        <img src={ project.compare.after } alt="После" />
                        <figcaption>После</figcaption>
                    </figure>
                </div>

                {
                    project.compare.comment !== "" ? (
                        <div className="project__container project__center">
                            <p className="project__25rem project__text-center">{ project.compare.comment }</p>
                        </div>
                    ) : null
                }

                <div className="project__container project__center">
                    <img className="project__image" src={ project.compare.sum } alt="Итог" />
                </div>

                {
                    project.blocks.map((block, i) => {
                        if (block.type === "text" && block.text !== "")
                            return (
                                <div key={ `block_${ i }` } className="project__container project__center">
                                    <p className="project__25rem project__text-center">{ block.text }</p>
                                </div>
                            )
                        else if (block.type === "images")
                            return (
                                <div key={ `block_${ i }` } className={`project__container--full ${ block.template }`}>
                                    {
                                        block.images.map((image, j) => (
                                            <img
                                                key={ `block_${ i }_image_${ j }` }
                                                className={ `project__image tmpl-${ j }` }
                                                src={ image }
                                                alt="" />
                                        ))
                                    }
                                </div>
                            )
                        else
                            return (
                                <div className="project__divider" />
                            )
                    })
                }
            </section>
            <section className="project__content">
                <div className="project__container project__container--column">
                    <h2>Материалы</h2>
                    <div className={ project.materials.template }>
                        {
                            project.materials.images.map((image, j) => (
                                <img
                                    key={ `material_image_${ j }` }
                                    className={ `project__image tmpl-${ j }` }
                                    src={ image }
                                    alt="" />
                            ))
                        }
                    </div>
                </div>
            </section>

            <section className="project__content">
                <div className="project__container--full">
                    <Feedback image={ project.form_image } />
                </div>
            </section>

            <div className="project__divider" />

            <section className="">
                <div className="project__container--full">
                    <SuggestedProjects projects={ projects } />
                </div>
            </section>

        </main>
    )
}

export default Project